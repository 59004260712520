export const designData = [
	{
		title: 'Etapy tworzenia produktu cyfrowego',
		text: 'Tworzenie produktu cyfrowego to proces, ponieważ ilość zmiennych decydujących o ostatecznych rozwiązaniach jest nieograniczona. W zależności od poziomu skomplikowania oraz punktu, w którym znajduje się projekt, do poszczególnych etapów wykorzystywane są różne moduły. Podyktowane jest to poszukiwaniem odpowiedzi na najistotniejsze kwestie, które mają wpływ na kluczowe zagadnienia związane z tym, jakie zadania ma realizować produkt końcowy.',
	},
	{
		title: 'ODKRYWANIE',
		text: 'Etap odkrywania to zebranie wszelkich dostępnych informacji, jakie wiążą się z projektem. Kluczowa jest tutaj sytuacja, w jakiej znajduje się dana usługa lub produkt, cele biznesowe, sposób zachowania się klientów, konteksty oraz insighty. Posiadając te dane, niezbędne jest stworzenie na podstawie analizy wniosków, które będą podstawą do dalszych działań.',
		content: [
			{
				title: 'START PROJEKTU',
				number: '01',
				data: [
					{
						text: 'KICK OFF'
					}
				]
			},
			{
				title: 'ZBIERANIE INFORMACJI',
				number: '02',
				data: [
					{
						text: 'KONSULTACJE'
					},
					{
						text: 'AUDYT'
					},
					{
						text: 'BADANIA'
					},
					{
						text: 'WARSZTATY'
					},
					{
						text: 'RESEARCH'
					},
					{
						text: 'DESK RESEARCH'
					},
					{
						text: 'ANALIZA KONKURENCJI'
					},
					{
						text: 'PROTOPERSONY'
					},
					{
						text: 'BADANIA JAKOŚCIOWE'
					},
					{
						text: 'BADANIA ILOŚCIOWE'
					},
				]
			},
			{
				title: 'ANALIZA',
				number: '03',
				data: [
					{
						text: 'SYNTEZA DANYCH'
					},
					{
						text: 'SEGMENTACJA RYNKU'
					}
				]
			}
		]
	},
	{
		title: 'STRATEGIA',
		text: 'Etap strategii to propozycje sposobu podejścia do rozwiązania problemów, które zostały zanalizowane w poprzednim etapie. Zostają wyznaczone potencjalne możliwości oraz bariery związane z poszczególnymi obszarami. Wspólnie określamy, jakie działania zostają podjęte, oraz którymi ścieżkami rozwoju będziemy podążać w dalszych etapach realizacji.',
		content: [
			{
				title: 'STRATEGIA ROZWOJU',
				number: '01',
				data: [
					{
						text: 'PODSUMOWANIE WYNIKÓW BADAŃ'
					},
					{
						text: 'OCENA RYZYKA I POTENCJALNYCH BARIER'
					},
					{
						text: 'STWORZENIE OSIĄGALNEJ KONCEPCJI PRODUKTU'
					},
					{
						text: 'OKREŚLENIE METODYK DO BUDOWANIA ROZWIĄZAŃ'
					},
					{
						text: 'OKREŚLENIE MIERZALNYCH METRYK'
					},
					{
						text: 'WYZNACZENIE KAMIENI MILOWYCH DLA TWORZENIA PRODUKTU'
					}
				]
			},
			{
				title: 'EWALUACJA ZAŁOŻEŃ',
				number: '02',
				data: [
					{
						text: 'PREZENTACJA WNIOSKÓW',
					},
					{
						text: 'EWALUACJA ZAŁOŻEŃ',
					},
					{
						text: 'OKREŚLENIE KOLEJNYCH ETAPÓW',
					},
				]
			}
		]
	},
	{
		title: 'TWORZENIE ROZWIĄZAŃ',
		text: 'Na podstawie etapu odkrywania oraz strategii opracowujemy rozwiązania, które spełnią założenia biznesowe oraz jednocześnie odpowiedzą na potrzeby użytkowników, uwzględniając w nich sposób dostępności, sposób gradacji informacji, nawigację, ścieżki użytkownika i sposób komunikacji. Rozwiązania te są przedstawiane w postaci mapy, która daje ogólny wgląd w to, jakimi drogami może podążać użytkownik i jakie informacje będą mu przedstawiane na konkretnych etapach. Na końcu tego etapu decydujemy wspólnie, które ścieżki i funkcje mogą lub muszą być realizowane w pierwszej kolejności, a które będziemy mogli rozwijać w kolejnych iteracjach.',
		content: [
			{
				title: 'DEFINIOWANIE',
				number: '01',
				data: [
					{
						text: 'STRUKTURYZACJA PROCESÓW I USŁUG'
					},
					{
						text: 'PERSONY'
					},
					{
						text: 'WARSZTATY WEWNĘTRZNE'
					},
					{
						text: 'WARSZTATY ZEWNĘTRZNE Z KLIENTAMI'
					},
					{
						text: 'USTALENIE SPOSOBU KOMUNIKACJI'
					},
					{
						text: 'PROPOZYCJE WARTOŚCI'
					},
				]
			},
			{
				title: 'BUDOWANIE ROZWIĄZAŃ',
				number: '02',
				data: [
					{
						text: 'USER STORIES',
					},
					{
						text: 'USER JOURNEY',
					},
					{
						text: 'CUSTOMER JOURNEY MAP',
					},
					{
						text: 'CARD SORTING',
					},
					{
						text: 'DESIGN SPRINT',
					},
					{
						text: 'ROZWIĄZANIA DLA WCAG',
					},
				]
			},
			{
				title: 'ARCHITEKTURA INFORMACJI',
				number: '03',
				data: [
					{
						text: 'RAPID PROTOTYPING'
					},
					{
						text: 'USER FLOW'
					},
					{
						text: 'WIREFRAMES'
					},
				]
			},
			{
				title: 'PREZENTACJA ROZWIĄZAŃ',
				number: '04',
				data: [
					{
						text: 'PRZEDSTAWIENIE MVP'
					},
					{
						text: 'MOŻLIWOŚCI DLA DALSZEGO ROZWOJU'
					},
					{
						text: 'USTALENIE I POTWIERDZENIE METRYK'
					},
				]
			}
		]
	},
	{
		title: 'BUDOWANIE PROTOTYPU',
		text: 'Wypracowane rozwiązania zostają zamienione na formę makiety z rozplanowanymi elementami (teksty, zdjęcia, buttony itd.), która jest uproszczoną wersją docelową produktu cyfrowego. W następnym kroku makiety zostają ze sobą połączone w sposób, który umożliwia symulację nawigacji oraz przechodzenia przez poszczególne linki. Interaktywna makieta UX pozwala na sprawdzenie wybranych ścieżek użytkownika oraz daje możliwość sprawdzenia dostępności do informacji. Taka forma jest odpowiednia do przeprowadzenia testów z użytkownikami i pozwala na ewentualną szybką ewaluację rozwiązań, zanim całość zostanie przekazana do dalszej realizacji.',
		subtext: 'Zamknięty zbiór rozwiązań w postaci makiet pozwala na opracowanie warstwy wizualnej. Dopasowanie do brandu, dobór kolorystyki, zdjęć lub stworzenie ilustracji mają miejsce na tym etapie. W przypadku bardziej skomplikowanych i rozbudowanych produktów to moment na stworzenie design systemu. Po tym etapie istnieje możliwość przetestowania odbioru produktu przez użytkowników, aby sprawdzić ich reakcję na warstwę wizualną.',
		content: [
			{
				title: 'MAKIETY UX',
				number: '01',
				data: [
					{
						text: 'ZBUDOWANIE MAKIET UX DESKTOP'
					},
					{
						text: 'STWORZENIE INTERAKTYWNEJ WERSJI DESKTOP'
					},
					{
						text: 'ZBUDOWANIE MAKIET UX MOBILE'
					},
					{
						text: 'STWORZENIE INTERAKTYWNIEJ WERSJI MOBILE'
					},
				]
			},
			{
				title: 'TESTY UX',
				number: '02',
				data: [
					{
						text: 'BADANIA'
					},

					{
						text: 'EWALUACJA ROZWIĄZAŃ'
					}
				]
			},
			{
				title: 'WARSTWA WIZUALNA UI',
				number: '03',
				data: [
					{
						text: 'DOPASOWANIE DO BRANDU I WYMOGÓW WIZUALNYCH'
					},
					{
						text: 'STWORZENIE ROZWIĄZAŃ WIZUALNYCH'
					},
					{
						text: 'STWORZENIE STYLE GUIDE'
					},
					{
						text: 'CUSTOM ILUSTRATION'
					},
					{
						text: 'DESIGN SYSTEM'
					},
				]
			},
			{
				title: 'TESTY UI',
				number: '04',
				data: [
					{
						text: 'BADANIA'
					}
				]
			}
		]
	},
	{
		title: 'WDROŻENIE',
		text: 'Projekt zostaje przekazany w ręce programistów, którzy poprzez odpowiednie języki programowania zamieniają całość w kod i sprawiają, że produkt będzie działał online w określony przez wszystkie poprzednie etapy sposób. Na końcu tego etapu w zależności od potrzeb zostają podpięte narzędzia analityczne pozwalające na zbieranie danych, które pomogą w dalszym rozwoju produktu.',
		content: [
			{
				title: 'FRONT-END',
				number: '01',
				data: [
					{
						text: 'ZAMIANA WARSTWY WIZUALNEJ NA KOD'
					}
				]
			},
			{
				title: 'BACK-END',
				number: '02',
				data: [
					{
						text: 'KOD ODPOWIADAJĄCY ZA DZIAŁANIE POSZCZEGÓLNYCH FUNKCJI NA STRONIE'
					}
				]
			},
			{
				title: 'NARZĘDZIA ANALITYCZNE',
				number: '03',
				data: [
					{
						text: 'PODPIĘCIE NARZĘDZI ANALITYCZNYCH'
					}
				]
			},
		]
	}
]
