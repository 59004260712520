<template>
	<section class="d-process">
		<div class="bg-img">
			<div class="d-process__content width-144">
				<div class="d-process-first__header">
					<div class="d-process-first__header-top">
						<router-link to="/">
							<img src="@/assets/images/logo_white.svg" alt="logo">
						</router-link>

						<Button
							buttonStyle="default"
							buttonLink="/kontakt"
							buttonText="Porozmawiajmy"
						></Button>
					</div>

					<div class="d-process-first__header-content">
						<div class="d-process-first__header-heading">
							<h4 class="h4-style">product & service design</h4>
							<h2 class="h2-style h2-sm-style">{{ designData[0].title }}</h2>
						</div>
						<p class="p-p-style">{{ designData[0].text }}</p>
					</div>
				</div>

				<section class="d-process-first__container">
					<div class="d-process-first__content">

						<div class="d-step d-step-first">
							<div class="d-step__header">
								<h2 class="d-h2-style --after-number-line">
									{{ designData[1].title }}

									<span class="d-number-style">01</span>
								</h2>

								<div class="d-step__header-text">
									<p class="p-p-style">{{ designData[1].text }}</p>
								</div>
							</div>

							<div class="d-step__content">
								<div class="d-step__row">
									<div class="d-step__row-title">
										<h4 class="h4-style --font-weight-700">{{ designData[1].content[0].title }}</h4>
									</div>

									<div class="d-step__row-number first-step-first-row--number">
										<h3>{{ designData[1].content[0].number }}</h3>
									</div>

									<div class="d-step__row-list --width-580">
										<ul>
											<li
												v-for="(item, index) in designData[1].content[0].data"
												:key="item.text + index"
												class="d-step__row-list-item"
											>
												<span class="h4-style">{{ item.text }}</span>
											</li>
										</ul>
									</div>
								</div>

								<div class="d-step__row first-step--second-row">
									<div class="d-step__row-title">
										<h4 class="h4-style --font-weight-700">{{ designData[1].content[1].title }}</h4>
									</div>

									<div class="d-step__row-number first-step-second-row--number">
										<h3>{{ designData[1].content[1].number }}</h3>
									</div>

									<div class="d-step__row-list --width-580">
										<ul>
											<li
												v-for="(item, index) in designData[1].content[1].data"
												:key="item.text + index"
												class="d-step__row-list-item"
											>
												<span class="h4-style">{{ item.text }}</span>
											</li>
										</ul>
									</div>
								</div>

								<div class="d-step__row first-step--third-row">
									<div class="d-step__row-title">
										<h4 class="h4-style --font-weight-700">{{ designData[1].content[2].title }}</h4>
									</div>

									<div class="d-step__row-number first-step--number">
										<h3>{{ designData[1].content[2].number }}</h3>
									</div>

									<div class="d-step__row-list">
										<ul>
											<li
												v-for="(item, index) in designData[1].content[2].data"
												:key="item.text + index"
												class="d-step__row-list-item"
											>
												<span class="h4-style">{{ item.text }}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>


						<div class="d-step d-step-first">
							<div class="d-step__header">
								<h2 class="d-h2-style --after-number-line">
									{{ designData[2].title }}

									<span class="d-number-style">02</span>
								</h2>

								<div class="d-step__header-text">
									<p class="p-p-style">{{ designData[2].text }}</p>
								</div>
							</div>

							<div class="d-step__content">
								<div class="d-step__row second-step--first-row">
									<div class="d-step__row-title">
										<h4 class="h4-style --font-weight-700">{{ designData[2].content[0].title }}</h4>
									</div>

									<div class="d-step__row-number second-step-first-row--number">
										<h3>{{ designData[2].content[0].number }}</h3>
									</div>

									<div class="d-step__row-list">
										<ul>
											<li
												v-for="(item, index) in designData[2].content[0].data"
												:key="item.text + index"
												class="d-step__row-list-item --width-120"
											>
												<span class="h4-style">{{ item.text }}</span>
											</li>
										</ul>
									</div>
								</div>

								<div class="d-step__row second-step--second-row">
									<div class="d-step__row-title">
										<h4 class="h4-style --font-weight-700">{{ designData[2].content[1].title }}</h4>
									</div>

									<div class="d-step__row-number second-step-second-row--number">
										<h3>{{ designData[2].content[1].number }}</h3>
									</div>

									<div class="d-step__row-list --width-580">
										<ul>
											<li
												v-for="(item, index) in designData[2].content[1].data"
												:key="item.text + index"
												class="d-step__row-list-item --width-120"
											>
												<span class="h4-style">{{ item.text }}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>


		<section class="d-process-third solutions">
			<div class="solutions__content width-144">
				<div class="d-step d-step-third">
					<div class="d-step__header">
						<h2 class="d-h2-style third-step--header-number">
							{{ designData[3].title }}

							<span class="d-number-style">03</span>
						</h2>

						<div class="d-step__header-text">
							<p class="p-p-style">{{ designData[3].text }}</p>
						</div>
					</div>

					<div class="d-step__content">
						<div class="d-step__row third-step-first-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[3].content[0].title }}</h4>
							</div>

							<div class="d-step__row-number third-step-first-row--number">
								<h3>{{ designData[3].content[0].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[3].content[0].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row third-step--second-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[3].content[1].title }}</h4>
							</div>

							<div class="d-step__row-number third-step-second-row--number">
								<h3>{{ designData[3].content[1].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[3].content[1].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row third-step--third-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[3].content[2].title }}</h4>
							</div>

							<div class="d-step__row-number third-step-third-row--number">
								<h3>{{ designData[3].content[2].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[3].content[2].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row third-step-four-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[3].content[3].title }}</h4>
							</div>

							<div class="d-step__row-number">
								<h3>{{ designData[3].content[3].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[3].content[3].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>


		<section class="d-process-four prototype">
			<div class="solutions__content width-144">
				<div class="d-step d-step-third">
					<div class="d-step__header">
						<h2 class="d-h2-style third-step--header-number">
							{{ designData[4].title }}

							<span class="d-number-style">04</span>
						</h2>

						<div class="d-step__header-text">
							<p class="p-p-style">{{ designData[4].text }}</p>
						</div>
					</div>

					<div class="d-step__content">
						<div class="d-step__row four-step-first-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[4].content[0].title }}</h4>
							</div>

							<div class="d-step__row-number four-step-first-row--number">
								<h3>{{ designData[4].content[0].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[4].content[0].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row four-step--second-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[4].content[1].title }}</h4>
							</div>

							<div class="d-step__row-number four-step-second-row--number">
								<h3>{{ designData[4].content[1].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[4].content[1].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="prototype__subtext">
							<p class="p-p-style">
								{{ designData[4].subtext }}
							</p>
						</div>

						<div class="d-step__row four-step--third-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[4].content[2].title }}</h4>
							</div>

							<div class="d-step__row-number four-step-third-row--number">
								<h3>{{ designData[4].content[2].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[4].content[2].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row four-step-four-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[4].content[3].title }}</h4>
							</div>

							<div class="d-step__row-number">
								<h3>{{ designData[4].content[3].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[4].content[3].data"
										:key="item.text + index"
										class="d-step__row-list-item"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>


		<section class="d-process-five deploy">
			<div class="solutions__content width-144">
				<div class="d-step d-step-third">
					<div class="d-step__header">
						<h2 class="d-h2-style --after-number-line">
							{{ designData[5].title }}

							<span class="d-number-style">05</span>
						</h2>

						<div class="d-step__header-text">
							<p class="p-p-style">{{ designData[5].text }}</p>
						</div>
					</div>

					<div class="d-step__content">
						<div class="d-step__row five-step-first-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[5].content[0].title }}</h4>
							</div>

							<div class="d-step__row-number five-step-first-row--number">
								<h3>{{ designData[5].content[0].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[5].content[0].data"
										:key="item.text + index"
										class="d-step__row-list-item --width-310"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row five-step--second-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[5].content[1].title }}</h4>
							</div>

							<div class="d-step__row-number five-step-second-row--number">
								<h3>{{ designData[5].content[1].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[5].content[1].data"
										:key="item.text + index"
										class="d-step__row-list-item --width-310"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="d-step__row five-step--third-row">
							<div class="d-step__row-title">
								<h4 class="h4-style --font-weight-700">{{ designData[5].content[2].title }}</h4>
							</div>

							<div class="d-step__row-number five-step-third-row--number">
								<h3>{{ designData[5].content[2].number }}</h3>
							</div>

							<div class="d-step__row-list">
								<ul>
									<li
										v-for="(item, index) in designData[5].content[2].data"
										:key="item.text + index"
										class="d-step__row-list-item --width-310"
									>
										<span class="h4-style">{{ item.text }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<FAQComponent currentButton="produkt"/>
	</section>
</template>

<script>
import {designData} from '@/data/designProcess'
import FAQComponent from '@/components/FAQComponent/index'

export default {
	name: "index design process",
	components: {FAQComponent},
	data() {
		return {
			designData
		}
	}
}
</script>

<style src="./style.sass" scoped lang="sass"/>

<style lang="sass">
.d-h2-style
	font-size: 45px
	font-weight: $weight--semi-bold
	font-family: $font--primary
	position: relative
	margin-bottom: 23px

	@include view('sm')
		font-size: 30px

.--after-number-line
	font-weight: 900 !important

	&::after
		position: absolute
		top: 50%
		right: 6%
		content: ''
		width: 534px
		height: 1px
		background: #979797

		@include view('md')
			display: none

		@include view('sm')
			width: 125px
			right: 0
			left: 50%
			transform: translateX(50%)

.d-number-style
	position: absolute
	font-size: 204px
	font-family: $font--primary
	font-weight: $weight--semi-bold
	opacity: .11
	top: -200%
	right: 0

	@include view('md')
		top: -90%
		font-size: 120px

	@include view('sm')
		top: -50%
		font-size: 67px
</style>
